.info {
    width: 12vw;
    font-size: 0.8vw;
    margin-top: 20%;
}
.icon-gap {
    margin-bottom: 5%;   
}
.info p {
    margin-top: -1.5%;
}
.info .icon-gap {
    margin-left: 0.8%;
    color: rgb(222, 64, 64);
}
.custom-icon, .infoContent {
    color: black;
}
