.image {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.image img {
    width: 12vw;
    height: 100%;
    margin: 10%;
    border-radius: 10%;
}